import React, { Component } from 'react';
import './CollapsibleElement.css';


class CollapsibleElement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            topRef: React.createRef(),
            botRef: React.createRef()
        };
    }

    render = () => {
        let contentStyle = {
            height: this.state.collapsed ? '0' : `${this.getContentHeight()}px`
        };
        return (
            <div
                className={`collapsibleElement ${
                    this.props.className ? this.props.className : ''
                    }`}
            >
                <div
                    className="collapsibleElement__header"
                    onClick={this.toggleCollapsed}
                >
                    <div className="collapsibleElement__headerContent">
                        {this.props.header}
                    </div>
                    <div
                        className={`collapsibleElement__collapseButton ${
                            this.state.collapsed
                                ? 'collapsibleElement__collapseButton--collapsed'
                                : ''
                            }`}
                    >

                        <svg
                            width="16"
                            height="10"
                            viewBox="0 0 16 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0.866682 1.06663C1.38215 0.551169 2.21788 0.551168 2.73335 1.06663L7.93335 6.26663L13.1333 1.06663C13.6488 0.551169 14.4846 0.551168 15 1.06663C15.5155 1.5821 15.5155 2.41784 15 2.9333L7.93335 9.99997L0.866682 2.9333C0.351216 2.41783 0.351217 1.5821 0.866682 1.06663Z"
                                fill="#fff"
                                // fill="#2098FB"
                            />
                        </svg>
                    </div>
                </div>
                <div
                    className={'collapsibleElement__content'}
                    style={contentStyle}
                >
                    <div ref={el => (this.topRef = el)}>
                        {this.props.children}
                    </div>
                    <div ref={el => (this.botRef = el)} />
                </div>
            </div>
        );
    };

    componentDidMount = () => {
        this.props.setToggleCollapsedCallback &&
        this.props.setToggleCollapsedCallback(this.toggleCollapsed);
        if (!this.props.startCollapsed) {
            setTimeout(() => {
                this.setState({ collapsed: false });
            }, 1);
        }
    };

    componentWillUnmount = () =>
        this.props.setToggleCollapsedCallback &&
        this.props.setToggleCollapsedCallback();

    toggleCollapsed = () => this.setState({ collapsed: !this.state.collapsed });

    getContentHeight = () => {
        // using 2 separate measuring points in order to take margins into account
        let top = this.topRef;
        let bot = this.botRef;
        if (top && bot) {
            return (
                bot.getBoundingClientRect().y -
                top.getBoundingClientRect().y +
                10
            );
        }
    };
}

export default CollapsibleElement;