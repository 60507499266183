// UPKEEP
import woop from '../resources/upkeep/woop.mp3';
import drum from '../resources/upkeep/drum.mp3';
import plong from '../resources/upkeep/plong.mp3';
import pling from '../resources/upkeep/pling.mp3';
import fog from '../resources/upkeep/fog.mp3';
import plonk from '../resources/upkeep/plonk.mp3';
import shamisen from '../resources/upkeep/shamisen.mp3';
import glitch from '../resources/upkeep/glitch.mp3';
import one_city from '../resources/upkeep/one_city.mp3';
import badum_tish from '../resources/upkeep/badum_tish.mp3';

// EASTER
import desert_easter from '../resources/easter/desert_easter.mp3';
import swoosh from '../resources/easter/swoosh.mp3';
import derp from '../resources/easter/derp.mp3';
import foggy_easter from '../resources/easter/foggy_easter.mp3';


const WOOP = 'woop';
const PLONG = 'plong';
const PLING = 'pling';
const DRUM = 'drum';
const FOG = 'fog';
const PLONK = 'plonk';
const SHAMISEN = 'shamisen';
const GLITCH = 'glitch';
const ONE_CITY = 'one_city';
const BADUM_TISH = 'badum_tish';

export const soundKeys = [
    PLING,
    WOOP,
    PLONG,
    SHAMISEN,
    PLONK,
    DRUM,
    FOG,
    GLITCH,
    ONE_CITY,
    BADUM_TISH,
];

export const sounds = {
    plonk,
    woop,
    plong,
    drum,
    pling,
    shamisen,
    glitch,
    one_city,
    fog,
    badum_tish,
};

const easterSounds = {
    one_city: desert_easter,
    badum_tish: swoosh,
    glitch: derp,
    fog: foggy_easter,
};

let mute = true;
let upkeepAudio;
let upkeepSoundIndex;
let easterAudio;
export const upkeepSoundSetup = (key, initial) => {
    console.log('upkeepSoundSetup', key);
    upkeepAudio = [];
    if (easterAudio)
        easterAudio.load();
    if (key) {
        mute = false;
        let sound = sounds[key];
        for (let i = 0; i < 4; i++) {
            upkeepAudio.push(
                new Audio(sound)
            )
        }
        upkeepSoundIndex = 1;
        upkeepAudio.forEach(sound => sound.load());

        if (easterSounds[key]){
            easterAudio = new Audio(easterSounds[key]);
            easterAudio.load();
        } else {
            easterAudio = undefined;
        }

        if (!initial)
            playUpkeepSound();
    } else {
        mute = true;
    }
};

export const playUpkeepSound = easter => {
    if (!mute) {
        if (easterAudio){
            if (easter)
                return playEasterSound();
            else if (!easterAudio.paused)
                easterAudio.load();
        }
        let current = upkeepSoundIndex;
        let next = current < upkeepAudio.length - 1 ? current + 1 : 0;
        upkeepAudio[current].currentTime = 0;
        upkeepAudio[current].play();
        upkeepAudio[next].load();
        upkeepSoundIndex = next;
    }
};

const playEasterSound = () => {
    if (easterAudio.paused || easterAudio.ended)
        easterAudio.play();
    else
        easterAudio.load();
};