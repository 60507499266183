import React, { Component } from 'react';
import './v1.css';
import {Icon} from "../Icon";

class V1 extends Component {
    constructor(){
        super();
        this.state = {
            baseLife: 20,
            life: 20
        }
    }

    render() {
        const shortSide = Math.min(window.innerWidth, window.innerHeight);
        const longSide = Math.max(window.innerWidth, window.innerHeight);


        const length = `${this.state.life}`.length;
        const multiplier = (10-((length-1)*2)) / 10;
        const margin = this.state.life > 9 && this.state.life < 20 ? shortSide / 20 : 0;

        const cornerButtonSize = shortSide / 10;
        const cornerButtonStyle ={
            height: cornerButtonSize,
            width: cornerButtonSize,
            margin: cornerButtonSize / 10,
            borderRadius: cornerButtonSize,
        };

        const bigIconSide = Math.min(longSide * .35, shortSide * .7);
        const bigIconStyle = {
            height: bigIconSide,
            width: bigIconSide,
        };

        const lifeStyle = this.state.life > 5 ? {} : this.state.life > 0 ? {
            animation: `blinker .${1+this.state.life}s linear infinite`
        } : {
            color: 'red'
        };

        return (
            <div className='v1'>
                <div className="mainClickSurfaceContainer">
                    <div className="addLife"
                         onClick={()=>{this.setState({life: this.state.life +1})}}
                    >
                        <div
                            className="plus"
                            style={bigIconStyle}
                        />
                    </div>
                    <div className="subtractLife"
                         onClick={()=>{this.setState({life: this.state.life -1})}}
                    >
                        <div
                            className="minus"
                            style={bigIconStyle}
                        />
                    </div>
                </div>
                <div
                    className="reset"
                    style={cornerButtonStyle}
                    onClick={() => {this.setState({life: this.state.baseLife})}}
                >
                    <Icon type='reset'/>
                </div>
                <div
                    className='display '
                    style={{
                        ...lifeStyle,
                        fontSize: shortSide * multiplier,
                        marginRight: margin,
                    }}
                >
                    {this.state.life}
                </div>
            </div>
        );
    }
}

export default V1;
