import {Fragment} from "react";
import React from "react";
import './Modal.css'


export const Modal = props => {
    const short = props.short;
    let margin = Math.min(window.innerWidth, window.innerHeight) / 20;
    let buttonSize = margin * 2;

    let buttonStyle = {
        height: margin * 3,
        width: margin * 5,
        borderRadius: buttonSize
    };



    let style = short === undefined ? {
        left: `${margin}px`,
        right: `${margin}px`,
    } : props.settings.historyLeft ? {
        left: `${short + 20}px`,
        right: `${margin}px`,
    } : {
        right: `${short + 20}px`,
        left: `${margin}px`,
    };

    style = {
        ...style,
        position: 'fixed',
        top: `${(window.innerHeight * .3) }px`,
        bottom: `${(window.innerHeight * .3) }px`,
        borderRadius: `${margin}px`,
        padding: `${margin}px`,
        fontSize: margin,
    };

    return props.showModal ? (
            <div
                className="v2_modal"
                style={style}
            >
                <div className="v2_modalContent">
                    {props.children}
                </div>
                <div className="v2_modalButtonWrapper">
                    <div
                        className="v2_modalButton v2_yasButton"
                        style={buttonStyle}
                        onClick={props.onConfirm}
                    >YAS!
                    </div>
                    <div
                        className="v2_modalButton v2_nahButton"
                        style={buttonStyle}
                        onClick={props.closeModal}
                    >Nah.
                    </div>
                </div>
            </div>
    ) : <Fragment/>;
};