import React, {Component, Fragment} from 'react';
import './v2.css';
import {margin} from "./utils";
import Orb from "./orb";
import Menu from "./menu";
import {Icon} from "../Icon";
import './soundManager'
import * as sounds from "./soundManager";
import {Modal} from "./Modal/Modal";

class V2 extends Component {
    constructor() {
        super();
        const storedSettings = localStorage.getItem('settings');
        const settings = storedSettings ? JSON.parse(storedSettings) :
            {
                historyLeft: true,
                showHistory: true,
                historyFocusChange: true,
                swipeMode: false,
                baseLife: 20,
            };

        this.state = {
            freshChange: false,
            showMenu: false,
            settings,
            change: 0,
            editing: undefined,
            reverting: undefined,
            changeHistory: [],
            // changeHistory: [-1, -2, -3, 3, -1, -2, -3, 3, -1, -2, -3, 3]
        };
    }

    componentDidMount() {
        sounds.upkeepSoundSetup(this.state.settings.sound, true);
    }

    componentDidUpdate() {
        if (this.lastElementRef) {
            this.lastElementRef.scrollIntoView({behavior: 'smooth', block: 'start'});
            this.lastElementRef = undefined;
            this.addRef = false;
        }
    }

    render = () => {
        let short = this.state.settings.showHistory ? Math.round(window.innerWidth * .2) : 0;
        let long = window.innerWidth - short;
        let longOrHalfHeight = Math.min(long, window.innerHeight / 2);

        let [menuSide, historySide, margin] = this.state.settings.historyLeft ? ['right', 'left', 'marginRight'] : ['left', 'right', 'marginLeft'];

        let menuButtonSize = Math.min(window.innerWidth, window.innerHeight / 2) * .1;
        let menuButtonStyle = {
            [menuSide]: '10px',
            top: '10px',
            height: menuButtonSize,
            width: menuButtonSize,
            borderRadius: menuButtonSize
        };

        let shorter = Math.round(short * .9);
        let longish = longOrHalfHeight * .9;


        let history = this.renderHistory();

        let life = history[history.length - 1].life + this.state.change;
        const length = `${life}`.length;
        const multiplier = (10 - ((length) * 2)) / 9;

        let mainStyle = {
            [historySide]: short,
        };

        let sideStyle = {
            width: short,
            [margin]: long,
        };

        let thing = 3;
        let changeButtonIconStyle = {
            height: longOrHalfHeight * (thing / 10),
            width: longOrHalfHeight * (Math.sqrt(Math.pow(thing, 2) + Math.pow(thing / 2, 2)) / 10), // Pythagoras up in this motherfucker
        };

        let longWidthStyle = {width: `${long}px`};

        let changeButtonStyle = {
            ...longWidthStyle,
            height: longOrHalfHeight * .4,
            // width: longOrHalfHeight * .4,
        };

        return (
            <Fragment>
                <button
                    className="v2_menuButton"
                    style={menuButtonStyle}
                    onClick={this.toggleMenu}
                >
                    {/*<div*/}
                    {/*style={{display: 'flex'}}*/}
                    {/*>*/}

                    <Icon
                        type={'settings'}
                        mod={this.state.showMenu ? 'black' : 'white'}
                    />
                    {/*</div>*/}
                </button>
                {this.state.showMenu ?
                    <Menu
                        saveSettings={this.saveSettings}
                        resetHistory={this.resetHistory}
                        settings={this.state.settings}
                    /> : ''
                }
                {this.renderChangePastPrompt(short)}
                {
                    this.state.settings.showHistory ?
                        <div
                            className="v2_history"
                            style={sideStyle}
                        >
                            {history.map((x, i) => this.renderHistoryElement(x.life, x.change, x.i, shorter, shorter, x.ref, i === history.length -1))}
                        </div> : ''
                }
                <div
                    className="v2_main"
                    style={mainStyle}
                >
                    <div
                        className="v2_changeButton v2_addLifeButton"
                        onClick={() => this.addChange(1)}
                        style={changeButtonStyle}
                    >
                        <div
                            style={changeButtonIconStyle}
                            className="v2_changeButtonIcon v2_addLifeButtonIcon"
                        />
                    </div>
                    <div
                        className="v2_globeContainer"
                        style={longWidthStyle}
                    >
                        {this.renderLifeGlobe(longish, life, longOrHalfHeight * multiplier)}
                    </div>
                    <div
                        className="v2_changeButton v2_addLifeButton"
                        onClick={() => this.addChange(-1)}
                        style={changeButtonStyle}
                    >
                        <div
                            style={changeButtonIconStyle}
                            className="v2_changeButtonIcon v2_subtractLifeButtonIcon"
                        />
                    </div>
                </div>

            </Fragment>
        );
    };

    saveSettings = (stateChange) => {
        let settings = {...this.state.settings, ...stateChange};
        localStorage.setItem('settings', JSON.stringify(settings));
        this.setState({settings});
    };

    toggleMenu = () => {
        this.setState(state => {
            return {
                ...state,
                showMenu: !state.showMenu
            }
        })
    };

    renderChangePastPrompt = (short) => {
        return (
            <Modal
                showModal={this.state.reverting !== undefined && this.state.changeHistory[this.state.reverting]}
                short={short}
                settings={this.state.settings}
                onConfirm={this.changePast}
                closeModal={this.cancelChangePast}
            >
                <div className="v2_revertPromptText">
                    Revert event {this.state.reverting + 1}:
                    ({(this.state.changeHistory[this.state.reverting] > 0 ? '+' : '') + this.state.changeHistory[this.state.reverting]})?
                </div>
        </Modal>
        );
    };

    addChange = change => {
        this.setState({
            change: this.state.change + change
        })
    };

    renderLifeGlobe = (size, life, fontSize) => <Orb
        size={size}
        life={life}
        change={this.state.change}
        onClick={() => this.record(life === 0)}
        main={true}
    >
        <div
            className="v2_lifeGlobeText"
            style={{
                fontSize
            }}
        >
            {life}
        </div>
    </Orb>;

    renderHistory = () => {
        let i = 0;
        let prevStepLife = this.state.settings.baseLife;

        return [
            {life: this.state.settings.baseLife, change: 0, i: i++}
            , ...this.state.changeHistory.map(change => {
                let ref = i === this.state.changeHistory.length;
                let life = prevStepLife + change;
                prevStepLife = life;
                return {life, change, i: i++, ref}
            })];
    };


    record = zero => {
        if (this.state.change !== 0) {
            this.addRef = true;
            this.setState({
                changeHistory: [...this.state.changeHistory, this.state.change],
                change: 0,
                freshChange: true
            });
            if (this.freshTimeout){
                clearTimeout(this.freshTimeout);
                this.freshTimeout = undefined;
            }
            this.freshTimeout = setTimeout(() => {
                this.setState({
                    freshChange: false
                });
                this.freshTimeout = undefined;
            },1000)

        } else {
            sounds.playUpkeepSound(zero);
        }
    };

    //v2_fresh
    renderHistoryElement = (life, change, key, side, sizeBase, ref, fresh) => {
        const lifeMultiplier = (10 - ((`${life}`.length))) / 8;
        const hightLighted = this.state.reverting === key - 1;
        const primary = this.state.settings.historyFocusChange ?
            change === 0 ? '¤' : Math.abs(change) :
            life;
        const secondary = this.state.settings.historyFocusChange ?
            life > 0 ? `${life}` : life :
            change === 0 ? '¤' : change > 0 ? `+${change}` : change;

        return <div className="v2_historyElement"
                    style={{
                        margin: `${side * .03}px 0 ${side * .03}px ${side * .06}px`
                    }}
                    key={key}
        >
            <Orb
                size={side}
                change={change === '¤' ? 0 : change}
                hightLighted={hightLighted}
                fresh={fresh && this.state.freshChange}
                onClick={() => this.changePastPrompt(key)}
                refz={ref && this.addRef ? x => this.lastElementRef = x : () => {
                }}
            >
                <div className="v2_historyElementText v2_lifeGlobeText">
                    <div
                        className="v2_historyLife"
                        style={{
                            height: sizeBase * .45,
                            fontSize: sizeBase * .5 * lifeMultiplier,
                            marginRight: margin(life, sizeBase),
                        }}
                    >
                        {primary}
                    </div>
                    <div
                        className="v2_historyChange"
                        style={{
                            fontSize: sizeBase * .20,
                        }}
                    >
                        ({secondary})
                    </div>
                </div>
            </Orb>
        </div>;
    };

    changePastPrompt = i => {
        if (i > 0) {
            if (i - 1 !== this.state.reverting) {
                this.setState({
                    reverting: i - 1
                })
            } else {
                this.cancelChangePast();
            }
        }
    };

    changePast = () => {
        let changeHistory = [...this.state.changeHistory];
        changeHistory.splice(this.state.reverting, 1);
        this.setState({
            changeHistory,
            reverting: undefined
        });
    };

    cancelChangePast = () => {
        this.setState({
            reverting: undefined
        })
    };

    resetHistory = () => {
        console.log('blapping a  foo.');
        this.setState({
            changeHistory: []
        })
    }

}

export default V2;