import React, {Component} from 'react';

//props: size, change, keyHole, hightLighted

class Orb extends Component {
    render = () =>
        <div
            className={`v2_lifeGlobe ${
                this.props.change === 0
                    ? 'v2_lifeGlobeGray'
                    : this.props.change > 0
                    ? 'v2_lifeGlobeGreen'
                    : 'v2_lifeGlobeRed'}${
                this.props.hightLighted
                    ? ' v2_highLight': ''
            }${
                    this.props.fresh
                    // (this.props.main && this.props.change !== 0)
                    ? ' v2_fresh'
                        : ''
                    }`}
            style={{
                height: this.props.size,
                width: this.props.size,
                borderRadius: this.props.size,
            }}
            onClick={this.props.onClick}
            ref={this.props.refz}
        >
            <div
                className={`${
                    this.props.change === 0
                        ? 'v2_lifeGlobeGrayLightish'
                        : this.props.change > 0
                        ? 'v2_lifeGlobeGreenLightish'
                        : 'v2_lifeGlobeRedLightish'
                }`}
                style={{
                    height: `${this.props.size * .9}px`,
                    width: `${this.props.size * .9}px`,
                    top: `${this.props.size * .03}px`,
                    left: `${this.props.size * .05}px`,
                    borderRadius: `${this.props.size}px`
                }}
            />
            <div
                className="v2_lifeGlobeLight"
                style={{
                    height: `${this.props.size * .6}px`,
                    width: `${this.props.size * .6}px`,
                    top: `${this.props.size * .1}px`,
                    left: `${this.props.size * .15}px`,
                    borderRadius: `${this.props.size}px`
                }}
            />
            <div
                className="v2_lifeGlobeSheen"
                style={{
                    height: `${this.props.size * .2}px`,
                    width: `${this.props.size * .2}px`,
                    borderRadius: `${this.props.size}px`,
                    top: `${this.props.size * .2}px`,
                    left: `${this.props.size * .3}px`,
                }}
            />
                {this.props.children}
        </div>;
}
export default Orb;