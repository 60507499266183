import React, {Component} from 'react';
import CollapsibleElement from "./CollapsibleElement/CollapsibleElement";
import {soundKeys, upkeepSoundSetup} from "./soundManager";
import {Modal} from "./Modal/Modal";

class Menu extends Component {
    constructor() {
        super();
        this.state = {
            baseLife: '',
            showResetHistoryModal: false
        };
    }

    render = () =>
        <div
            className={'v2_menu'}
        >
            <h1 className="v2_menu_header"> Options: </h1>
            <div className="v2_menu_content">
                {this.renderResetHistoryModal()}
                {this.renderResetHistoryOption()}
                {this.renderBaseLifeOptions()}
                {this.renderHistoryOptions()}
                {this.renderSoundSettings()}
            </div>
        </div>;

    renderResetHistoryModal = () => {

        let closeModal= () => this.setState({showResetHistoryModal: false});
        return (
            <Modal
                showModal={this.state.showResetHistoryModal}
                settings={this.state.settings}
                onConfirm={() => {
                    this.props.resetHistory();
                    closeModal();
                }}
                closeModal={closeModal}
            >
                <div className="">
                    <p>
                        Reset all life history?
                    </p>
                    <p>
                        Equivalent to reloading browser.
                    </p>
                    <p>
                        Or manually reverting each event..
                    </p>
                </div>
            </Modal>
        );
    };
    renderResetHistoryOption = () => (
        <div
            className="resetButtonContainer"
        >
            <button
                onClick={() =>
                    this.setState({showResetHistoryModal: true})
                }
            >
                Reset History
            </button>
        </div>
    );

    renderBaseLifeOptions = () => <form
        className="v2_menu_setBaseLife"
        onSubmit={e => {
            e.preventDefault();
            this.setBaseHealth();
        }}
    >
        <label
            htmlFor="v2_menu_baseLifeInput"
            className="baseLifeInputLabel"
        >
            Base life:
        </label>
        <br/>
        <input
            type="number"
            id="v2_menu_baseLifeInput"
            className="v2_menu_baseLifeInput"
            onChange={e => this.setState({baseLife: +e.target.value})}
        />
        <button
            className="v2_setBaseLifeConfirmButton"
        >
            Yep
        </button>
    </form>;

    renderHistoryOptions = () => {
        return (
            <CollapsibleElement
                header={
                    <h4> History: </h4>
                }
                className={'editProfileLeaveInfo'}
                startCollapsed={true}
                wholeHeaderClickable={true}
            >
                <div className="v2_menu_historyOptions">
                    <div className="v2_menu_showHisotry v2_menu_historyOptionGroup">
                        <label>Show:</label>
                        <form
                            className={'v2_menu_showHistoryForm'}
                        >
                            <label
                                className={'v2_menu_showHistoryOption'}
                            >
                                Left
                                <input
                                    type="radio"
                                    value="left"
                                    checked={this.props.settings.showHistory && this.props.settings.historyLeft}
                                    onChange={e => {
                                        this.props.saveSettings({
                                            showHistory: true,
                                            historyLeft: true,
                                        })
                                    }}
                                />
                            </label>
                            <label
                                className={'v2_menu_showHistoryOption'}
                            >
                                Right
                                <input
                                    type="radio"
                                    value="right"
                                    checked={this.props.settings.showHistory && !this.props.settings.historyLeft}
                                    onChange={e => {
                                        this.props.saveSettings({
                                            showHistory: true,
                                            historyLeft: false,
                                        });
                                    }}
                                />
                            </label>
                            <label
                                className={'v2_menu_showHistoryOption'}
                            >
                                Hide
                                <input
                                    type="radio"
                                    value="hidden"
                                    checked={!this.props.settings.showHistory}
                                    onChange={e => {
                                        this.props.saveSettings({
                                            showHistory: !this.props.settings.showHistory
                                        })
                                    }}
                                />
                            </label>
                        </form>
                    </div>
                    <div className="v2_menu_historyFocusChange v2_menu_historyOptionGroup">
                        <label
                            htmlFor="v2_menu_historyFocusChangeCheck"
                            className="v2_menu_historyFocusChangeCheckLabel"
                        >
                            Focus change
                        </label>
                        <input
                            type="checkbox"
                            id="v2_menu_historyFocusChangeCheck"
                            className="v2_menu_historyFocusChangeCheck"
                            checked={this.props.settings.historyFocusChange}
                            onChange={e => {
                                this.props.saveSettings({historyFocusChange: e.target.checked})
                            }}
                        />
                    </div>
                </div>
            </CollapsibleElement>
        )
    };

    renderSoundSettings = () => {
        return (
            <CollapsibleElement
                header={
                    <h4>Upkeep sound:</h4>
                }
                className={'editProfileLeaveInfo'}
                startCollapsed={true}
                wholeHeaderClickable={true}
            >
                <section
                    className="editProfileLeaveInfo__content"
                >
                    <form
                        className={'v2_menu_showHistoryForm'}
                    >

                        {this.renderSoundRadioButton('mute', true)}
                        {soundKeys.map(key => this.renderSoundRadioButton(key))}
                    </form>


                    {/*{this.renderSoundOptionButton('mute', true)}*/}
                    {/*{*/}
                        {/*soundKeys.map(key => this.renderSoundOptionButton(key))*/}
                    {/*}*/}
                </section>
            </CollapsibleElement>
        )
    };

    renderSoundRadioButton = (key, mute) => (
        <label
            className={'v2_menu_showHistoryOption'}
            key={key}
        >
            {key}
            <input
                type="radio"
                value={key}
                checked={
                    (mute && this.props.settings.sound === undefined) ||
                    this.props.settings.sound === key
                }
                onChange={() => {
                    this.props.saveSettings({sound: mute ? undefined : key});
                    upkeepSoundSetup(mute ? undefined : key);
                }}
            />
        </label>
    );

    setBaseHealth = () => {
        let baseLife = this.state.baseLife;
        if (baseLife > 0) {
            this.props.saveSettings({baseLife});
        }
    };

}

export default Menu;