import React, { Component } from 'react';
import './App.css';
import V1 from "./v1/v1";
import V2 from "./v2/v2";

class App extends Component {
    constructor(){
        super();
        this.state = {
            v1: false,
        }
    }

  render() {
      return (
      <div className='App'>
          {this.state.v1 ? <V1/> : <V2/>}
      </div>
    );
  }
}

export default App;
